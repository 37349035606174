<template>
    <div class="social-auth">
        <div class="separator">
            OR
        </div>
        <div class="social-options row">
            <!-- <div class="social-circle">
                <img src="@assets/img/icons/social/google.svg">
            </div> -->
            <button class="social-circle middle" @click="handleFBLogin">
                <img src="@assets/img/icons/social/facebook-login.svg">
            </button>
            <!-- <div class="social-circle">
                <img src="@assets/img/icons/social/apple.svg">
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "SocialAuth",
    props: {
        processAuth: {
            type: Function,
            required: true
        }
    },
    methods: {
        getSingleWord(string, position) {
            return string.split(" ")[position];
        },
        requestApi(user, provider) {
            const data = new FormData();

            if (user.email && user.name) {
                data.append("email", user.email);
                data.append("firstname", this.getSingleWord(user.name, 0));
                data.append("lastname", this.getSingleWord(user.name, 1));
            }
            data.append("social_id", user.id);
            data.append("provider", provider);

            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            axios.post("/users/social", data, config).then((response) => {
                this.processAuth(response.data);
            })
                .catch((error) => {
                    this.handleErrors(error?.response?.data?.errors?.message);
                });
        },
        handleFBLogin() {
            FB.login((response) => {
                if (response.authResponse) {
                    FB.api("/me?fields=name,picture,email", (response) => {
                        this.requestApi(response, "facebook");
                    });
                } else {
                    this.handleErrors("Error authenticating with social media");
                }
            });
        },
        handleErrors(text) {
            this.$notify({
                group: "auth-notifications",
                type: "error",
                text: text || "Please, try again later!",
                duration: 3000
            });
        }
    }
}
</script>

<style lang="scss" scoped>
/* form {
    padding-bottom: 0px !important;
} */

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 11px;
    margin-top: 32px;
    margin-bottom: 32px;

    @media(max-height: 650px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(158, 159, 165, 0.6);;
}

.separator::before {
    margin-right: 26px;
}

.separator::after {
    margin-left: 26px;
}

.social-circle {
    width: 44px;
    height: 44px;
    background-color: rgba(116, 116, 128, 0.08);
    border-color: rgba(116, 116, 128, 0.08);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

}

.social-options {
    justify-content: center;
}

.middle {
    margin-left: 16px;
    margin-right: 16px;
}

.social-auth {
    margin: 0 auto;
    max-width: 300px;
    padding-bottom: 70px;

    @media(max-height: 650px) {
        padding-bottom: 25px;
    }
}
</style>
