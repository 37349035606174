var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in-form auth-form"},[_c('h1',[_vm._v("Sign In")]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleEmaillogIn)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"mode":"lazy","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length }},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email "),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",attrs:{"id":"email","type":"text","placeholder":"Enter your email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length }},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password "),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",attrs:{"id":"password","type":"password","placeholder":"Enter your password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}})])]}}],null,true)}),_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"disabled":!valid}},[_vm._v(" Sign In ")]),_c('router-link',{staticClass:"forgot-password-link",attrs:{"to":{ name: 'forgot-password' }}},[_vm._v(" Forgot your password? ")])],1)])]),_c('social-auth',{attrs:{"process-auth":_vm.processLogin}}),_c('span',{staticClass:"change-component"},[_vm._v(" New here? "),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('set-auth-form', 'sign-up-form')}}},[_vm._v("Sign up")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }