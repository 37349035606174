<template>
    <div class="sign-in-form auth-form">
        <h1>Sign In</h1>
        <ValidationObserver v-slot="{ valid, handleSubmit }" slim>
            <form novalidate @submit.prevent="handleSubmit(handleEmaillogIn)">
                <div class="row">
                    <div class="col">
                        <ValidationProvider
                            v-slot="{ errors }"
                            mode="lazy"
                            name="email"
                            rules="required|email"
                        >
                            <div :class="{ 'required' : errors.length }" class="form-group">
                                <label for="email">Email <span v-if="errors.length">({{ errors[0] }})</span></label>
                                <input
                                    id="email"
                                    v-model="email"
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter your email"
                                >
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="password"
                            rules="required"
                        >
                            <div :class="{ 'required' : errors.length }" class="form-group">
                                <label for="password">Password <span v-if="errors.length">({{ errors[0] }})</span></label>
                                <input
                                    id="password"
                                    v-model="password"
                                    type="password"
                                    class="form-control"
                                    placeholder="Enter your password"
                                >
                            </div>
                        </ValidationProvider>
                        <button :disabled="!valid" class="btn btn-primary btn-block">
                            Sign In
                        </button>
                        <router-link class="forgot-password-link" :to="{ name: 'forgot-password' }">
                            Forgot your password?
                        </router-link>
                    </div>
                </div>
            </form>
            <social-auth :process-auth="processLogin" />
            <!--
            <div
                id="appleid-signin"
                data-color="black"
                data-border="true"
                data-type="sign in"
            /> -->
            <span class="change-component">
                New here? <a class="" href="javascript:void(0)" @click="$emit('set-auth-form', 'sign-up-form')">Sign up</a>
            </span>
        </validationObserver>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { setTokens } from "@/utils";

export default {
    name: "SignInForm",
    components: {
        SocialAuth: () => import(/* webpackChunkName: "sign-in-form"*/ "./social-auth")
    },
    data() {
        return {
            email: "",
            password: "",
            isLoading: false
        };
    },
    watch: {
        isLoading() {
            this.$emit("is-loading", this.isLoading);
        }
    },
    methods: {
        ...mapActions({
            emaillogIn: "User/emaillogIn"
        }),
        processLogin(data) {
            this.$emit("auth-sucessfull", data);
            this.$store.dispatch("User/setToken", data.token);
            setTokens(data);
        },
        async handleEmaillogIn() {
            const userData = {
                email: this.email,
                password: this.password
            }

            try {
                this.isLoading = true;
                const data = await this.emaillogIn(userData);
                this.processLogin(data);
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                this.handleErrors(error?.response?.data?.errors?.message);
            }
        },
        handleErrors(text) {
            this.$notify({
                group: "auth-notifications",
                type: "error",
                text: text || "Please, try again later!",
                duration: 3000
            });
        }
    }
}
</script>
